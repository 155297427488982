import React, { useState } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SEO from "../components/seo";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import PageTransition from "gatsby-plugin-page-transitions";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  validateEmail,
  validateLength,
  validateName,
} from "../utils/validation";

import style from "./coming-soon.module.scss";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#720000",
      contrastText: "#fff",
    },
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ComingSoon = ({ data }) => {
  const [client, setClient] = useState({ email: "", name: "" });
  const [clientError, setClientError] = useState({
    email: { hasError: false, message: "" },
    name: { hasError: false, message: "" },
  });
  const [errorSnack, setErrorSnack] = useState(false);

  const LoadingSwal = withReactContent(Swal);

  const Loading = () => {
    LoadingSwal.fire({
      title: "¡Registrando!",
      html: (
        <>
          <CircularProgress
            style={{ color: "#720000", margin: "1rem 0" }}
            size={80}
          />
        </>
      ),
      allowOutsideClick: false,
      heightAuto: false,
      showConfirmButton: false,
      width: "20rem",
    });
  };

  const Success = Swal.mixin({
    title: "¡Exito!",
    icon: "success",
    html: `<h4>¡Gracias por subscribirte!</h4><br/><small>*Si no ve un correo, no olvide revisar su bandeja de no deseados.`,
    confirmButtonColor: "#720000",
  });

  const ErrorSwal = (
    status,
    message = "Ocurrió un error. Intente de nuevo por favor."
  ) => {
    let errorMessage = message;
    if (status === 400) {
      errorMessage =
        message === "Invalid Resource"
          ? "Correo falso o no valido. Por favor ingresar otro correo."
          : message === "Member Exists"
          ? "El correo ya está registrado. Intentar con otro correo."
          : message === "Forgotten Email Not Subscribed"
          ? 'Error al registrarse, por favor intentar con <a href="http://eepurl.com/hmQo41" target="_blank">este</a> formulario.'
          : message;
    } else if (status === 429) {
      errorMessage =
        "Límite de solicitudes excedido, intente de nuevo en 5 minutos.";
    }

    Swal.mixin({
      icon: "error",
      title: "¡Ups!",
      html: `${errorMessage}`,
    }).fire();
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setClient((prevVal) => {
      return {
        ...prevVal,
        [name]: value,
      };
    });
  };

  const handleSnack = () => {
    setErrorSnack(false);
  };

  const validate = (e) => {
    const { name: field, value } = e.target;
    if (field === "email") {
      if (client.email === "") {
        setClientError((prevVal) => {
          return {
            ...prevVal,
            email: {
              hasError: true,
              message: "*Se requiere un correo electrónico.",
            },
          };
        });
        return;
      }

      if (!validateEmail(value.trim())) {
        setClientError((prevVal) => {
          return {
            ...prevVal,
            email: {
              hasError: true,
              message: "* Ingrese correo electrónico valido.",
            },
          };
        });
        return;
      }

      if (clientError.email.hasError) {
        setClientError((prevVal) => {
          return {
            ...prevVal,
            email: {
              hasError: false,
              message: "",
            },
          };
        });
        return;
      }
    } else {
      if (client.name !== "") {
        if (!validateLength(value, 50)) {
          setClientError((prevVal) => {
            return {
              ...prevVal,
              name: {
                hasError: true,
                message: "*Campo requiere entre 2-50 caracteres.",
              },
            };
          });
          return;
        }

        if (!validateName(value)) {
          setClientError((prevVal) => {
            return {
              ...prevVal,
              name: {
                hasError: true,
                message: "*Ingrese un nombre valido.",
              },
            };
          });
          return;
        }
      }

      if (clientError.name.hasError) {
        setClientError((prevVal) => {
          return {
            ...prevVal,
            name: {
              hasError: false,
              message: "",
            },
          };
        });
        return;
      }
    }
  };

  const validateEmpty = () => {
    if (client.email === "") {
      setClientError((prevVal) => {
        return {
          ...prevVal,
          email: {
            hasError: true,
            message: "*Se requiere un correo electrónico.",
          },
        };
      });
      return false;
    }
    return true;
  };

  const sendEmail = async () => {
    try {
      if (!validateEmpty()) return;
      if (clientError.email.hasError || clientError.name.hasError) {
        setErrorSnack(true);
        return;
      }
      const { name, email } = client;
      const data = { name: name.trim(), email: email.trim() };
      Loading();
      const response = await axios.post(
        "https://glamar-services.herokuapp.com/services/mailchimp/",
        data
      );
      if (response.status === 200) {
        LoadingSwal.close();
        Success.fire();
        setClient({ email: "", name: "" });
      }
    } catch (error) {
      console.log(error);
      LoadingSwal.close();
      if (error.message === "Network Error") {
        ErrorSwal(0);
      } else {
        const { data, status } = error.response;
        ErrorSwal(status, data.message);
      }
    }
  };

  return (
    <PageTransition transitionTime={1000}>
      <SEO title="Llegaremos Pronto" />
      <ThemeProvider theme={theme}>
        <header>
          {/* <img
            className={style.logo}
            src="../../images/logo.svg"
            alt="glamar-logo"
          /> */}
          <Img
            className={style.logo}
            fluid={data.glamarLogo.childImageSharp.fluid}
            alt="glamar-logo"
            imgStyle={{ objectFit: "contain" }}
          />
        </header>
        <main>
          <Grid container className={style.main}>
            <Grid item xs={12} md={6} className={style.comingSoon}>
              <p className={style.title}>¡LLEGAREMOS PRONTO!</p>
              {/* <img
                className={style.travlers}
                src="../../images/rafiki.png"
                alt="travlers"
              /> */}
              <Img
                className={style.travlers}
                fluid={data.glamarTravlers.childImageSharp.fluid}
                alt="travlers"
                imgStyle={{ objectFit: "contain" }}
              />
            </Grid>
            <Grid item xs={12} md={6} className={style.signUp}>
              <p className={style.message}>
                ¡<span>H</span>ola viajero! Estamos trabajando para brindarte
                una mejor experiencia. ¡Suscríbete para mantenerte al día con
                nuestros anuncios y ofertas de lanzamiento!
              </p>
              <TextField
                id="email"
                name="email"
                label="Correo Electrónico *"
                variant="outlined"
                color="primary"
                autoComplete="off"
                className={style.emailInput}
                onChange={handleChange}
                value={client.email}
                size="small"
                error={clientError.email.hasError}
                helperText={clientError.email.message}
                onBlur={validate}
              />
              <TextField
                id="name"
                name="name"
                label="Nombre(s)"
                variant="outlined"
                color="primary"
                autoComplete="off"
                className={style.nameInput}
                onChange={handleChange}
                value={client.name}
                helperText={clientError.name.message}
                size="small"
                error={clientError.name.hasError}
                onBlur={validate}
              />
              <Button
                className={style.subscribeButton}
                size="large"
                variant="contained"
                color="primary"
                onClick={sendEmail}
              >
                SUSCRIBIRME
              </Button>
            </Grid>
          </Grid>
        </main>
        <footer>
          <p className="text-muted">
            © GlaMar Travel {new Date().getFullYear()}
          </p>
        </footer>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={errorSnack}
          onClose={handleSnack}
          autoHideDuration={5000}
        >
          <Alert onClose={handleSnack} severity="error">
            Verificar campos.
          </Alert>
        </Snackbar>
      </ThemeProvider>
    </PageTransition>
  );
};

export default ComingSoon;

export const query = graphql`
  query {
    glamarLogo: file(relativePath: { eq: "page-images/logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    glamarTravlers: file(relativePath: { eq: "page-images/travlers.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
