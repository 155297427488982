const validateLength = (value, maxLength, minLength = 2) => {
    return (value.length >= minLength && value.length <= maxLength);
}

const validateName = (value) => {
    return (/^[A-Za-zÑñÁÉÍÓÚáéíóú\s]+$/.test(value));
}

const validateEmail = (value) => {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return (emailRex.test(value));
}

export {
    validateEmail,
    validateLength,
    validateName
}